.his_main[data-v-b16149c4] {
  position: relative;
  background-color: #f1f1f1;
}
.his_main .right_btns[data-v-b16149c4] {
  position: absolute;
  top: 7px;
  right: 40px;
  z-index: 99;
}
.el-date-editor.el-input[data-v-b16149c4], .el-date-editor.el-input__inner[data-v-b16149c4] {
  width: 180px !important;
}
[data-v-b16149c4] .el-tabs__content {
  background-color: #f1f1f1 !important;
}